export function removeUndefinedValues(obj) {
  const cleanedObject = {};
  for (const key in obj) {
    if (obj[key] !== undefined) {
      if (Array.isArray(obj[key]) && !obj[key].length) {
        continue;
      }
      cleanedObject[key] = obj[key];
    }
  }
  return cleanedObject;
}

export function focusSearch() {
  const element =document.querySelector('#search-section')
  const offset = 65;
  const bodyRect = document.body.getBoundingClientRect().top;
  const elementRect = element.getBoundingClientRect().top;
  const elementPosition = elementRect - bodyRect;
  const offsetPosition = elementPosition - offset;

  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth",
  });
}
