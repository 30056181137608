import {
    Card,
    CardHeader,
    CardBody,
    Typography,
  } from "@material-tailwind/react";
import { Link } from "react-router-dom";
   
  export default function SuggestionCard({id,title,image}) {
    return (
      <Link to={`/recipe/${id}`}>
      <Card
        shadow={false}
        className="relative grid h-[10rem] w-full max-w-[28rem] items-end justify-center overflow-hidden text-center"
      >
        <CardHeader
          floated={false}
          shadow={false}
          color="transparent"
          style={{background:`url(${image})`}}
          className='absolute inset-0 m-0 h-full w-full rounded-none  bg-cover bg-center'
        >
          <div className="to-bg-black-10 absolute inset-0 h-full w-full bg-gradient-to-t from-black/80 via-black/50" />
        </CardHeader>
        <CardBody className="relative py-14 px-6 md:px-12">
          <Typography
            variant="h6"
            color="white"
            className=" font-medium leading-[1.5]"
          >
           {title}
          </Typography>
        </CardBody>
      </Card>
      </Link>
    );
  }