import DetailSection from "../components/DetailSection";
import { SimpleFooter } from "../components/Fotter";
import Header from "../components/Navbar";


const RecipeDetail = () => {
  return (
    <>
      <Header />
      <DetailSection/>
      <SimpleFooter />
    </>
  );
};

export default RecipeDetail;
