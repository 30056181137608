import recipes from "../API/recipes";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Spinner, Typography } from "@material-tailwind/react";

const tagMap = {
  cheap: "Cheap",
  dairyFree: "Dairy Free",
  glutenFree: "Gluten Free",
  ketogenic: "Ketogenic",
  lowFodmap: "LowFodmap",
  sustainable: "Sustainable",
  vegan: "Vegan",
  vegetarian: "Vegetarian",
  veryHealthy: "Very Healthy",
  veryPopular: "Very Popular",
  whole30: "Whole 30",
};

const DetailSection = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  console.log({id})
  const allTags = [
    "cheap",
    "dairyFree",
    "glutenFree",
    "ketogenic",
    "lowFodmap",
    "sustainable",
    "vegan",
    "vegetarian",
    "veryHealthy",
    "veryPopular",
    "whole30",
  ];

  useEffect(() => {
    window.scroll(0, 0);
    recipes
      .getRecepieDetail(id)
      .then((res) => {
        localStorage.setItem("interested", id);
        setData(res.data);
      })
      .catch((err) => {
        window.location.href = "/";
      });
  }, [id]);

  const getTags = () => {
    let tags = [];
    // eslint-disable-next-line
    allTags.map((name) => {
      if (data[name]) {
        tags.push(
          <span className="bg-black text-white m-0.5 my-1 px-2 py-1 rounded-md text-sm">
            {tagMap[name]}
          </span>
        );
      }
    });
    return tags;
  };
  if (!data) {
    return (
      <div className="h-[80vh] flex justify-center items-center">
        <Spinner className="h-10 w-10 " />
      </div>
    );
  }
  return (
    <div className=" mx-auto w-[100%] max-w-[800px] p-3">
      <Typography className="my-2" variant="lead">
        {data.title}
      </Typography>
      <div className="mx-auto  border-gray-100">
        <img
          className="rounded-lg mx-[1%] w-[98%] border sm:w-auto sm:mx-auto my-2 "
          src={data.image}
          alt="banner"
        />
      </div>
      <div className="flex flex-wrap">{getTags()}</div>
      <div>
        <Typography className="mt-4 mb-2" variant="lead">
          Ingredients:
        </Typography>
        {data?.extendedIngredients?.map(({ original }, index) => (
          <Typography variant="paragraph">- {original}</Typography>
        ))}
      </div>
      <div className="mb-10">
        <Typography className="mt-4 mb-2" variant="lead">
          Instructions:
        </Typography>
        {data?.analyzedInstructions?.[0]?.steps?.map(
          ({ step, number }, index) => {
            return (
              <Typography className="my-2" variant="paragraph">
                {number})  {step}
              </Typography>
            );
          }
        )}
      </div>
    </div>
  );
};

export default DetailSection;
