import React from "react";
import { Navbar, Typography, IconButton } from "@material-tailwind/react";
import Logo from '../assets/logo/logo.png';
import { useLocation } from 'react-router-dom';  // Import useLocation hook

const Header = () => {
  const [openNav, setOpenNav] = React.useState(false);
  const location = useLocation();  // Get the current route

  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);

  const navList = (
    <ul className="flex gap-6">
      {/* Conditionally render About Me button if not on /about */}
      {location.pathname !== "/about" && (
        <Typography
          as="li"
          variant="small"
          color="blue-gray"
          className="p-1 font-normal"
        >
          <a href="/about" className="flex items-center">
            About Me
          </a>
        </Typography>
      )}

      {/* Home Button - always visible */}
      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="p-1 font-normal"
      >
        <a href="/" className="flex items-center">
          Home
        </a>
      </Typography>
    </ul>
  );

  return (
    <Navbar className="sticky top-0 z-10 h-max max-w-full rounded-none py-1 px-4 lg:px-8">
      <div className="flex items-center justify-between w-full">
        {/* Logo on the left */}
        <div className="flex items-center">
          <img src={Logo} alt="logo" className="mr-4 cursor-pointer font-medium" style={{ height: 60 }} />
        </div>

        {/* Navigation with conditionally rendered About Me */}
        <div className="flex items-center gap-4">
          <div className="mr-4 hidden lg:block">{navList}</div>

          {/* Mobile Icon Button (optional) */}
          <IconButton
            variant="text"
            className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
            ripple={false}
            onClick={() => setOpenNav(!openNav)}
          >
            {openNav ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                className="h-6 w-6"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            )}
          </IconButton>
        </div>
      </div>

      {/* Mobile Navigation */}
      {openNav && (
        <div className="lg:hidden">
          {navList}
        </div>
      )}
    </Navbar>
  );
};

export default Header;
