import { Typography } from "@material-tailwind/react"
import SuggestionCard from "./Cards/SuggestionCard";
import { useEffect, useState } from "react";
import recipes from "../API/recipes";
const Suggestion=()=>{
    const [data,setData]=useState([]);
    useEffect(()=>{
        recipes.getRecepieSuggestion()
        .then(res=>setData(res.data))
    },[])
    return(
        <div className="container m-auto py-4 justify-between">
           <Typography className="text-2xl font-medium">Suggested Recipes</Typography>
           <div className="py-5 px-5 grid gap-4    lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 min-[400px]:grid-cols-2 ">
           {data.map(({id,title,sourceUrl})=><SuggestionCard key={id} id={id} title={title} image={`'https://spoonacular.com/recipeImages/${id}-312x231.jpg'`}/>)} 
           </div>
        </div>
    )
}

export default Suggestion;