import axios from "axios";
import { removeUndefinedValues } from "../utils";
class recipes {
  static getRecepieSuggestion(){
    let id=localStorage.getItem('interested')||(parseInt(Math.random()*100))
    let payload = {
      apiKey: process.env.REACT_APP_API_KEY,
      number: 4,
    };
    return axios.get(`https://api.spoonacular.com/recipes/${id}/similar?${new URLSearchParams(payload)}`)
  }
  static getRecepies(options = {}) {
    const { number = 20, offset = 0, cuisine, diet, intolerances } = options;
    console.log({intolerances})
    let payload = {
      apiKey: process.env.REACT_APP_API_KEY,
      number: number,
      offset: offset,
      cuisine: cuisine,
      diet: diet,
      intolerances: intolerances?.map(({value})=>value).toString(),
    };
    return axios.get(
      `https://api.spoonacular.com/recipes/complexSearch?${new URLSearchParams(removeUndefinedValues(payload))}`
    );
  }

  static getRecepieDetail(id){
    let payload = {
      apiKey: process.env.REACT_APP_API_KEY,
    };
    return axios.get(
      `https://api.spoonacular.com/recipes/${id}/information?${new URLSearchParams(payload)}`)
  }
}

export default recipes;
