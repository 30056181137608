import { Typography, } from "@material-tailwind/react"
import RecipesCard from "./Cards/Card";
import { DefaultPagination } from "./Pagination";
import { RecepiesContext } from "../context/RepiesContext";
import { useContext } from "react";
const SearchResult=()=>{
    const {recepies}=useContext(RecepiesContext);
    return(
        <div className="container m-auto py-4 justify-between">
           <Typography id="search-section" className="text-2xl font-medium">Recipes for you </Typography>
           <div className="py-5 px-5 grid gap-4    lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 min-[400px]:grid-cols-2 ">
            {recepies.results?.map(({id,title,image})=><RecipesCard key={id} title={title} id={id} image={image}/>)}
           </div>
           <div className="m-auto w-max my-4">
           <DefaultPagination {...recepies}/>
           </div>
        </div>
    )
}

export default SearchResult;