import React, { useCallback, useMemo ,useContext} from "react";
import { Button, IconButton } from "@material-tailwind/react";
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";
import recipes from "../API/recipes";
import { RecepiesContext } from "../context/RepiesContext";
import { focusSearch } from "../utils";
 
export function DefaultPagination({number=20,offset=0,totalResults=5220,cuisine=undefined,diet=undefined,intolerances=[]}) {
  const {setRecepies}=useContext(RecepiesContext);


  const calcData=useMemo(()=>{
    //offset=20
    let value=offset/20; //1
    let startFrom=parseInt(value-(value%5));
    let endAt=(((startFrom+5)*20)<totalResults?startFrom+5:Math.ceil((totalResults/20)))
    return {startFrom,endAt}
  },[offset,totalResults])

  const makeApiCall=useCallback((offset)=>{
    recipes.getRecepies({offset,cuisine,diet,intolerances})
    .then(res=>{
      setRecepies({...res.data,cuisine,diet,intolerances})
      focusSearch();
    })
  },[cuisine,diet,intolerances,setRecepies])

  const getButton=useCallback(()=>{
    let buttons=[];
    for(let i=calcData.startFrom;i<calcData.endAt;i++){
      buttons.push( <IconButton variant={i===(offset/20)?'filled':'text'} onClick={()=>makeApiCall(i*20>totalResults?totalResults-20:i*20)}>{i+1}</IconButton>)
    }
    return buttons;

  },[calcData,offset,makeApiCall,totalResults])

  const next=()=>{
    let offset=(calcData.startFrom+5)*20 ;
    if(offset>totalResults){
      offset=totalResults-(totalResults%20);
    }
    makeApiCall(offset)
  }
  const prev=()=>{
    let offset=(calcData.startFrom-5)*20;
    makeApiCall(offset)
  }
   


  return (
    <div className="flex flex-col md:flex-row items-center gap-4">
      <Button
        variant="text"
        className="flex items-center gap-2"
         onClick={prev}
         disabled={offset<100}
      >
        <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" /> Previous
      </Button>
      <div className="flex items-center gap-2">
         {getButton()}
      </div>
      <Button
        variant="text"
        className="flex items-center gap-2"
        onClick={next}
        disabled={((offset+(20*5)))>=totalResults}
      >
        Next
        <ArrowRightIcon strokeWidth={2} className="h-4 w-4" />
      </Button>
    </div>
  );
}