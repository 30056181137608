import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Main from "./pages/Main";
import RecipeDetail from "./pages/RecipeDetail";
import Test from "./pages/test";
import AboutMe from './components/AboutMe';
import Header from './components/Navbar';  // Import the Header component

function App() {
  return (
    <BrowserRouter>
      {/* Render the Header on all pages */}
      <Header />  

      {/* Define the routes for your pages */}
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/test" element={<Test />} />
        <Route path="/recipe/:id" element={<RecipeDetail />} />
        <Route path="/about" element={<AboutMe />} />  {/* New route for About Me */}
        <Route path="*" element={<Navigate to="/" replace />} />  {/* Redirect for unknown paths */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
