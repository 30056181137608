import React from 'react';
import './AboutMe.css';  // Import the CSS file

const AboutMe = () => {
  return (
    <div className="about-me-container">
      {/* Title and Subheading */}
      <div className="about-me-header">
        <h1>About Me</h1>
        <p>
          Hey, I'm Ram, the founder of AllergyPatrol!<br />
          Come join me on this journey of spreading allergy awareness.
        </p>
      </div>

      {/* Profile Section */}
      <div className="about-me-card">
        {/* Profile Image */}
        <div className="about-me-image">
          <img src="stat/efwefwf.jpg" alt="Ram Sarma" />
        </div>

        {/* Profile Info */}
        <div className="about-me-info">
          <h2>Ram Sarma</h2>
          <h4>Founder and Developer</h4>
          <p>
         I am a passionate advocate for food allergy awareness, 
          combining technology and personal experience to help those affected by food allergies. 
          The inspiration behind Allergy Patrol stems from a desire to make navigating food 
          allergies easier and safer for individuals and families. Through the development of the 
          Allergy Patrol app, a platform has been created where users can effortlessly find 
          allergen-friendly foods, recipes, and resources.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutMe;
