import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Typography,
    Button,
  } from "@material-tailwind/react";
import { Link } from "react-router-dom";
   
  const RecipesCard=({title,image,id})=>{
    return (
      <Card shadow={true} className="w-[100%] max-w-[300px] m-auto">
        <CardHeader shadow={true} floated={false} className="h-56">
          <img
            src={image}
            alt="card"
            className="h-full w-full object-cover"
          />
        </CardHeader>
        <CardBody>
          <div className="mb-1 flex items-center justify-between">
            <Typography color="blue-gray" className="font-medium min-h-10">
              {title}
            </Typography>
          </div>
        </CardBody>
        <CardFooter className="pt-0">
          <Link to={`/recipe/${id}`}>
          <Button
            ripple={false}
            fullWidth={true}
            className="bg-blue-gray-900/10 text-blue-gray-900 shadow-none hover:scale-105 hover:shadow-none focus:scale-105 focus:shadow-none active:scale-100"
          >
            VIEW RECIPE
          </Button>
          </Link>
          
        </CardFooter>
      </Card>
    );
  }

  export default RecipesCard;