export const resultData = {
  results: [
    {
      id: 715497,
      title: "Berry Banana Breakfast Smoothie",
      image: "https://spoonacular.com/recipeImages/715497-312x231.jpg",
      imageType: "jpg",
    },
    {
      id: 715415,
      title: "Red Lentil Soup with Chicken and Turnips",
      image: "https://spoonacular.com/recipeImages/715415-312x231.jpg",
      imageType: "jpg",
    },
    {
      id: 716406,
      title: "Asparagus and Pea Soup: Real Convenience Food",
      image: "https://spoonacular.com/recipeImages/716406-312x231.jpg",
      imageType: "jpg",
    },
    {
      id: 644387,
      title: "Garlicky Kale",
      image: "https://spoonacular.com/recipeImages/644387-312x231.jpg",
      imageType: "jpg",
    },
    {
      id: 715446,
      title: "Slow Cooker Beef Stew",
      image: "https://spoonacular.com/recipeImages/715446-312x231.jpg",
      imageType: "jpg",
    },
    {
      id: 782601,
      title: "Red Kidney Bean Jambalaya",
      image: "https://spoonacular.com/recipeImages/782601-312x231.jpg",
      imageType: "jpg",
    },
    {
      id: 795751,
      title: "Chicken Fajita Stuffed Bell Pepper",
      image: "https://spoonacular.com/recipeImages/795751-312x231.jpg",
      imageType: "jpg",
    },
    {
      id: 766453,
      title: "Hummus and Za'atar",
      image: "https://spoonacular.com/recipeImages/766453-312x231.jpg",
      imageType: "jpg",
    },
    {
      id: 716627,
      title: "Easy Homemade Rice and Beans",
      image: "https://spoonacular.com/recipeImages/716627-312x231.jpg",
      imageType: "jpg",
    },
    {
      id: 664147,
      title: "Tuscan White Bean Soup with Olive Oil and Rosemary",
      image: "https://spoonacular.com/recipeImages/664147-312x231.jpg",
      imageType: "jpg",
    },
    {
      id: 640941,
      title: "Crunchy Brussels Sprouts Side Dish",
      image: "https://spoonacular.com/recipeImages/640941-312x231.jpg",
      imageType: "jpg",
    },
    {
      id: 715495,
      title: "Turkey Tomato Cheese Pizza",
      image: "https://spoonacular.com/recipeImages/715495-312x231.jpg",
      imageType: "jpg",
    },
    {
      id: 794349,
      title: "Broccoli and Chickpea Rice Salad",
      image: "https://spoonacular.com/recipeImages/794349-312x231.jpg",
      imageType: "jpg",
    },
    {
      id: 716381,
      title: "Nigerian Snail Stew",
      image: "https://spoonacular.com/recipeImages/716381-312x231.jpg",
      imageType: "jpg",
    },
    {
      id: 660306,
      title: "Slow Cooker: Pork and Garbanzo Beans",
      image: "https://spoonacular.com/recipeImages/660306-312x231.jpg",
      imageType: "jpg",
    },
    {
      id: 756814,
      title: "Powerhouse Almond Matcha Superfood Smoothie",
      image: "https://spoonacular.com/recipeImages/756814-312x231.jpg",
      imageType: "jpg",
    },
    {
      id: 715769,
      title: "Broccolini Quinoa Pilaf",
      image: "https://spoonacular.com/recipeImages/715769-312x231.jpg",
      imageType: "jpg",
    },
    {
      id: 642129,
      title: "Easy To Make Spring Rolls",
      image: "https://spoonacular.com/recipeImages/642129-312x231.jpg",
      imageType: "jpg",
    },
    {
      id: 642605,
      title: "Farro With Mushrooms and Asparagus",
      image: "https://spoonacular.com/recipeImages/642605-312x231.jpg",
      imageType: "jpg",
    },
    {
      id: 636589,
      title: "Butternut Squash Frittata",
      image: "https://spoonacular.com/recipeImages/636589-312x231.jpg",
      imageType: "jpg",
    },
    {
      id: 646738,
      title: "Herbivoracious' White Bean and Kale Soup",
      image: "https://spoonacular.com/recipeImages/646738-312x231.jpg",
      imageType: "jpg",
    },
    {
      id: 715540,
      title: "Summer Berry Salad",
      image: "https://spoonacular.com/recipeImages/715540-312x231.jpg",
      imageType: "jpg",
    },
    {
      id: 663559,
      title: "Tomato and lentil soup",
      image: "https://spoonacular.com/recipeImages/663559-312x231.jpg",
      imageType: "jpg",
    },
    {
      id: 662670,
      title: "Swiss Chard Wraps",
      image: "https://spoonacular.com/recipeImages/662670-312x231.jpg",
      imageType: "jpg",
    },
    {
      id: 640062,
      title: "Corn Avocado Salsa",
      image: "https://spoonacular.com/recipeImages/640062-312x231.jpg",
      imageType: "jpg",
    },
  ],
  offset: 2,
  number: 25,
  totalResults: 5220,
};

export const CuisinesData = [
  "African",
  "Asian",
  "American",
  "British",
  "Cajun",
  "Caribbean",
  "Chinese",
  "Eastern European",
  "European",
  "French",
  "German",
  "Greek",
  "Indian",
  "Irish",
  "Italian",
  "Japanese",
  "Jewish",
  "Korean",
  "Latin American",
  "Mediterranean",
  "Mexican",
  "Middle Eastern",
  "Nordic",
  "Southern",
  "Spanish",
  "Thai",
  "Vietnamese",
];

export const DietData = [
  "Gluten Free",
  "Ketogenic",
  "Vegetarian",
  "Lacto-Vegetarian",
  "Ovo-Vegetarian",
  "Vegan",
  "Pescetarian",
  "Paleo",
  "Primal",
  "Low FODMAP",
  "Whole30",
];

export const IntolerancesData = [
  "Dairy",
  "Egg",
  "Gluten",
  "Grain",
  "Peanut",
  "Seafood",
  "Sesame",
  "Shellfish",
  "Soy",
  "Sulfite",
  "Tree Nut",
  "Wheat",
];
